<template>
  <div class="mini_program_body">
    <!-- 选项卡切换 -->
    <div class="flexbox" style="height: 40px">
      <div
        :class="{ topbox: isAction !== 1, topbox1: isAction === 1 }"
        @click="changAction(1)"
      >
        <!-- 设置个案所有人 -->
        {{ $t("label.service.miniProgram.bindSetOwner") }}
      </div>
      <i class="el-icon-caret-right" style="padding: 0 40px"></i>
      <div
        :class="{ topbox: isAction !== 2, topbox1: isAction === 2 }"
        @click="changAction(2)"
      >
        <!-- 绑定网站 -->
        {{ $t("vue_label_systemSettings_bindweb") }}
      </div>
    </div>
    <!-- 展示区 -->
    <div class="showbox" v-show="isAction === 1">
      <!-- 选择所有人 -->
      <div class="title">{{ $t("label.bind.chooseone") }}</div>
      <div class="mainbox">
        <p>
          <!-- 通过网站提交的服务请求单可在系统内生成个案记录，请选择用户作为个案记录的创建人，选择的用户需要对个案对象具有创建权限。网页适用于电脑和手机。 -->
          {{ $t("label.case.text") }}
        </p>
        <div class="flexbox">
          <!-- 用户 -->
          <div class="userbox">{{ $t("label.emailsync.status.user") }}</div>
          <div>
            <el-input
              v-model="inputValueObj.name"
              placeholder="$t('message.please.choose.user')"
              @click.native="input_kuang_click_event"
            ></el-input>
          </div>
        </div>
        <!-- 底部保存按钮 -->
        <el-button
          type="primary"
          class="save_btn"
          @click="saveContact"
          :disabled="isDisabledSaveBtn"
          >{{ $t("label.tabpage.save") }}</el-button
        >
      </div>
    </div>
    <div class="showbox" v-show="isAction === 2">
      <!-- 复制网页链接 -->
      <div class="title">{{ $t("label.case.copy.link") }}</div>
      <div class="mainbox">
        <p>
          <!-- 我们已经将贵公司和网站版门户绑定，复制专属于贵公司的网站版客户服务门户地址，在贵公司官网上添加入口进入此网站。 -->
          {{ $t("label.case.text") }}
        </p>
        <img src="@/assets/images/toCase.png" class="imgbox" />
        <!-- 网页客户服务门户地址 -->
        <div>
          {{ $t("label.case.adress.link") }}：<span style="color: #006dcc">{{
            miniProgramPath + "?orgId=" + orgId
          }}</span>
        </div>
        <!-- 一键复制 -->
        <el-button
          type="primary"
          class="save_btn"
          @click="copyText(miniProgramPath + '?orgId=' + orgId)"
          >{{ $t("label.applt.copy") }}</el-button
        >
      </div>
      <!-- 二维码 -->
      <!-- 下载网页版客户服务门户二维码 -->
      <div class="title">{{ $t("label.case.copre") }}</div>
      <div class="mainbox">
        <p>
          <!-- 您也可以下载专属于贵公司的网页门户二维码，将二维码放到官网或设备上，客户可以扫描二维码使用网页版客户门户。 -->
          {{ $t("label.case.copre.text") }}
        </p>
        <div id="qrCode" ref="qrCodeDiv"></div>
        <!-- 一键复制 -->
        <el-button type="primary" class="save_btn" @click="downLoad">{{
          $t("label.applt.down.one")
        }}</el-button>
      </div>
    </div>

    <!-- 查找弹窗 -->
    <el-dialog
      :title="$t('label.user.list')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      style="height: 91%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
      @close="clear"
      destroy-on-close
    >
      <search-table ref="searchTable" @changeSelect="changeSlaveSelect" />
    </el-dialog>
  </div>
</template>

<script>
import {copyText} from "@/utils/copyInfo"
import QRCode from 'qrcodejs2'
import SearchTable from '../components/searchTable.vue'
import { editWeixinUser, getWeixinUser } from './api'
export default {
  components: { SearchTable },
  data() {
    return {
      miniProgramPath: "http://testwebtocc.cloudcc.com/", //写死，h5的首页地址
      isAction: 1, // 1:展示设置个案所有人，2：展示绑定网页
      isDisabledSaveBtn: true, //是否禁用保存
      inputValueObj: { name: "" }, //输入框绑定值
      // fieldId: "",
      // relevantObjId: "user",
      // relevantPrefix: "005",
      showSearchTable: false, //弹窗是否显示
      orgId: localStorage.getItem("orgId"), // 获取当前组织id
      isBind: false, // 是否用绑定的用户
    };
  },
  created() {},
  mounted() {
    this.getOrgid();
  },
  methods: {
    copyText,
    // 复制成功提示
    
    copy() {
      let that = this;
      this.$message({
        message: that.$i18n.t("label.copy.success"),
        type: "success",
      });
    },
    // 关闭弹窗，清空上次查询的条件
    clear() {
      this.$refs.searchTable.searchKeyWord = "";
    },
    // 下载二维码
    downLoad() {
      let that = this;
      let myCanvas = document
        .getElementById("qrCode")
        .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0].toDataURL("image/png");
      a.download = this.$i18n.t("label.qrcode");
      a.click();
      setTimeout(() => {
        this.$message({
          message: that.$i18n.t("label.updown.suess"),
          type: "success",
        });
      }, 1000);
    },
    // 生成二维码
    bindQRCode: function () {
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.miniProgramPath + "?orgId=" + this.orgId,
        width: 104,
        height: 104,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    //   点击切换选项卡
    changAction(val) {
      if (val === 2 && !this.isBind) {
        // 没有绑定用户，不允许切换到绑定网站
        let that = this;
        this.$message.error(that.$i18n.t("label.set.case"));
      } else {
        this.isAction = val;
      }
    },
    // 点击用户输入框
    input_kuang_click_event() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },
    // 获取orgid和绑定用户
    async getOrgid() {
      let obj = {
        orgid: this.orgId,
        integrate_type: "web",
      };
      let result = await getWeixinUser(obj);
      let userInfo = result.data;
      if (userInfo.userid) {
        this.inputValueObj = {
          name: userInfo.name,
          id: userInfo.userid,
          loginname: userInfo.loginname,
        };
        // 允许切换到绑定网站
        this.isBind = true;
      }

      // 生成二维码
      this.bindQRCode();
    },
    // 保存事件
    async saveContact() {
      let that = this;
      let { id, name, loginname } = this.inputValueObj;
      if (!id || !name || !loginname) {
        this.$message.error(that.$i18n.t("label.set.case"));
      } else {
        let res = await editWeixinUser({
          orgid: this.orgId,
          userid: id,
          name,
          loginname,
          integrate_type: "web",
        });
        if (res.returnCode == "1") {
          this.$message({
            showClose: true,
            type: "success",
            message: that.$i18n.t("savesuccess"),
          });
          // 跳转绑定页面
          this.isAction = 2;
        } else {
          this.$message({
            showClose: true,
            type: "fail",
            message: that.$i18n.t("savefail"),
          });
        }
        this.isDisabledSaveBtn = true;
      }
    },
    // 改变从对象查找/查找带值选项及值
    changeSlaveSelect(row) {
      this.inputValueObj = {
        name: row.name,
        id: row.id,
        loginname: row.loginname,
      };
      this.showSearchTable = false;
      this.isDisabledSaveBtn = false;
    },
  },
};
</script>

<style scoped lang="scss">
// 新样式
.flexbox {
  display: flex;
  align-items: center;
}
.mini_program_body {
  padding: 18px 10px;
  position: relative;
  height: 100%;
  .topbox {
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    padding-bottom: 2px;
  }
  .topbox1 {
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 2px;
    color: #006dcc;
    border-bottom: 2px solid #006dcc;
  }
  .showbox {
    min-height: calc(100% - 51px);
    margin-top: 15px;
    border-radius: 3px;
    border: 1px solid #dedcda;
    .title {
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
      font-weight: bold;
      color: #333333;
      background: #efefef;
    }
    .mainbox {
      padding: 0 20px;
      p {
        width: 50%;
        padding: 10px 0;
      }
      .userbox {
        height: 30px;
        line-height: 30px;
        margin-right: 20px;
        padding: 0 10px;
        border-radius: 3px;
        border: 1px solid #dcdcdc;
      }
      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      .imgbox {
        height: 226px;
      }
    }
  }
}
.save_btn {
  margin: 10px 0;
}
</style>
