/**
 * 小程序后台设置页
 */
import axios from "@/config/httpConfig";
/**
 * 绑定小程序
 * 保存cloudcc管理员用户id
 */
export function editWeixinUser(data) {
    return axios.post("/weixinapp/editWeixinUser", data);
}
// 查询cloudcc管理员用户
export function getWeixinUser(data) {
    return axios.post("/weixinapp/getWeixinUser", data);
}